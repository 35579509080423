import { useQuery } from '@tanstack/react-query'
import { CarrierGroupHistoryResponse } from '../models/Carrier/CarrierGroupHistoryResponse'
import {
  getCarrierContacts,
  getCarrierDetails,
  getCarrierGroupCarrierList,
  getCarrierGroupInfo,
  getCarrierGroupInfoByCarrierID,
  getCarrierGroupNameHistory,
  getCarrierInfo,
  getCarrierList,
  getCarrierLossCost,
  getCarrierRevisionHistory,
} from '../services/carrierService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'
import useLazyQuery from './useLazyQuery'

const useGetCarrierList = () =>
  useQuery({
    queryKey: [CacheKeys.Carriers],
    queryFn: getCarrierList,
    staleTime: StaleTime.ONE_MINUTE,
  })

const useGetCarrierGroupInfo = (carrierGuid: string) =>
  useQuery({
    queryKey: [CacheKeys.CarrierGroupInfo, carrierGuid],
    queryFn: () => getCarrierGroupInfo(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

const useGetCarrierGroupCarrierList = (carrierGuid: string) =>
  useQuery({
    queryKey: [CacheKeys.CarrierGroupCarrierList, carrierGuid],
    queryFn: () => getCarrierGroupCarrierList(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

const useGetCarrierGroupGutterInfo = (carrierGuid: string) => {
  return useQuery({
    queryKey: [CacheKeys.CarrierGroupGutterInfo, carrierGuid],
    queryFn: () => getCarrierGroupInfoByCarrierID(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })
}

const useGetCarrierDetails = (carrierGuid: string) =>
  useQuery({
    queryKey: [CacheKeys.CarrierDetails, carrierGuid],
    queryFn: () => getCarrierDetails(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

const useGetCarrierInfo = (carrierGuid: string) =>
  useQuery({
    queryKey: [CacheKeys.CarrierInfo, carrierGuid],
    queryFn: () => getCarrierInfo(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

const useGetCarrierLossCost = (carrierGuid: string) =>
  useLazyQuery({
    queryKey: [CacheKeys.CarrierLossCost, carrierGuid],
    queryFn: () => getCarrierLossCost(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

const useGetCarrierGroupNameHistory = (carrierGroupGuid: string) =>
  useLazyQuery<CarrierGroupHistoryResponse[]>({
    queryKey: [CacheKeys.CarrierGroupNameHistory, carrierGroupGuid],
    queryFn: () => getCarrierGroupNameHistory(carrierGroupGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
  })

const useGetCarrierContacts = (carrierGuid: string, carrierContactType: string) =>
  useLazyQuery({
    queryKey: [CacheKeys.CarrierLossCost, carrierGuid, carrierContactType],
    queryFn: () => getCarrierContacts(carrierGuid, carrierContactType),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid && !!carrierContactType,
  })

const useGetCarrierRevisionHistory = (carrierGuid: string) =>
  useLazyQuery({
    queryKey: [CacheKeys.CarrierRevisionHistory, carrierGuid],
    queryFn: () => getCarrierRevisionHistory(carrierGuid),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!carrierGuid,
  })

export {
  useGetCarrierList,
  useGetCarrierGroupInfo,
  useGetCarrierGroupCarrierList,
  useGetCarrierGroupGutterInfo,
  useGetCarrierGroupNameHistory,
  useGetCarrierDetails,
  useGetCarrierInfo,
  useGetCarrierLossCost,
  useGetCarrierContacts,
  useGetCarrierRevisionHistory,
}
